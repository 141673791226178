<template>
  <div>
    <Popover v-if="user.isLogin" v-slot="{ open }">
      <PopoverButton ref="buttonComp" class="focus:outline-none">
        <UserAvatar class="w-8 h-8" rounded :src="user.avatar" />
      </PopoverButton>

      <Transition
        enter-active-class="transform transition duration-100 ease-out"
        enter-from-class="scale-95 -translate-y-1/20 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-active-class="transform transition duration-75 ease-out"
        leave-from-class="scale-100 opacity-100"
        leave-to-class="scale-95 -translate-y-1/20 opacity-0"
      >
        <div v-show="open" ref="panelEl" class="panel z-50 absolute" :style="{ right: `${panelRight}px` }">
          <PopoverPanel static>
            <div class="flex mt-1 flex-col rounded-lg border-2 border-sky-300 shadow-md bg-white overflow-hidden">
              <div class="flex my-2 px-4 gap-3 items-center">
                <UserAvatar class="w-12 h-12" rounded :src="user.avatar" />
                <div>
                  <div class="font-medium">
                    {{ user.username }}
                  </div>
                  <div class="text-sm text-gray-600">
                    {{ user.email }}
                  </div>
                </div>
              </div>

              <div class="w-full border-1 border-sky-200" />

              <button class="flex px-4 py-1 w-full gap-2 hover:bg-sky-50" @click="() => user.clear()">
                <div class="i-carbon:exit text-xl" />
                退出登录
              </button>
            </div>
          </PopoverPanel>
        </div>
      </Transition>
    </Popover>

    <RouterLink v-else :to="`/login?from=${encodeURIComponent(route.fullPath)}`">
      <UserAvatar class="w-8 h-8" rounded :src="defaultAvatar" />
    </RouterLink>
  </div>
</template>

<script lang="ts" setup>
import type { GlobalComponents } from 'vue'
import defaultAvatar from '@/assets/akarin.jpg'

const route = useRoute()
const user = useUserStore()

const buttonComp = shallowRef<InstanceType<GlobalComponents['PopoverButton']> | null>(null)
const buttonEl = computed<HTMLButtonElement | null>(() => buttonComp.value?.$el)
const panelEl = shallowRef<HTMLDivElement | null>(null)

const { x: buttonX, width: buttonWidth } = useElementBounding(buttonEl)
const { width: panelWidth } = useElementSize(panelEl)

const buttonCenterRight = $computed(() => window.innerWidth - buttonX.value - buttonWidth.value / 2)
const panelRight = $computed(() => Math.max(buttonCenterRight - panelWidth.value / 2, 6))
const arrowRight = $computed(() => buttonCenterRight - panelRight - 4)
</script>

<style scoped>
.panel::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
  background: white;
  border-top: 2px solid theme('colors.sky.300');
  border-left: 2px solid theme('colors.sky.300');
  position: absolute;
  top: 1px;
  right: v-bind("`${arrowRight}px`");
}
</style>
