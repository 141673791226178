import type { FfprobeStream } from 'fluent-ffmpeg'
import { $fetch } from 'ohmyfetch'

export { FetchError } from 'ohmyfetch'

export const fetchBE = $fetch.create({ baseURL: import.meta.env.VITE_BACKEND_BASE })

export interface ApiPublicLogin {
  id: number
  token: string
  status: number
}

export interface ApiUserById {
  avatar: {
    type: 'gravatar'
    url: string
  } | undefined
  bio: string
  email: string
  id: number
  lastip: string
  lastlogin: number
  playlist: []
  regip: string
  regtime: number
  username: string
}

export interface ApiSongSearch {
  total: number
  max_score: number
  results: {
    id: number
    title: string
    album: string
    artists: string[]
    description: string
    score: number
  }[]
}

export interface ApiSongInfo {
  id: number
  title: string
  album: string
  artists: string[]
  level: number
  type: 0
  classify: number
  uploader: number
  description: string
  PV: null
  duration: string
  createdAt: number
  updatedAt: number
  cover: string | null
  streams: {
    codec: string
    playurl: string
    metadata: FfprobeStream | null
  }[]
}
