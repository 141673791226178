<template>
  <Combobox v-model="selectedItemRaw" as="div" nullable class="relative">
    <div class="flex px-3 flex-nowrap items-center rounded-full bg-white overflow-hidden ring ring-sky-300">
      <ComboboxInput
        class="focus:outline-none w-full py-1"
        placeholder="搜索 音乐 / 专辑"
        :display-value="displayValue"
        @change="(e) => input = e.target.value"
      />
      <button class="i-carbon:search text-gray-600" />
    </div>

    <Transition
      enter-active-class="transform transition duration-100 ease-out"
      enter-from-class="scale-95 -translate-y-1/20 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="transform transition duration-75 ease-out"
      leave-from-class="scale-100 opacity-100"
      leave-to-class="scale-95 -translate-y-1/20 opacity-0"
    >
      <ComboboxOptions class="absolute mt-2 w-full rounded-lg ring-2 ring-sky-300 bg-white overflow-hidden">
        <div
          v-if="results.total === 0 && !input"
          class="px-2 py-2 w-full text-center text-gray-400"
        >
          输入一个关键词搜索
        </div>

        <ComboboxOption
          v-if="input.length > 0"
          v-slot="{ active }"
          as="template"
          :value="encodeItem({ type: 'search', input })"
        >
          <li
            class="px-2 py-1 w-full border-b border-sky-200 last:border-b-0 transition-colors duration-75 cursor-pointer"
            :class="{ 'bg-sky-200 bg-opacity-10': active }"
          >
            <div class="truncate">
              <span class="i-carbon:search inline-block mr-px text-sky-600 text-sm transform translate-y-0.5" />
              <span
                class="transition-colors duration-75"
                :class="{ 'text-sky-500': active }"
              >
                {{ input }}
              </span>
            </div>
          </li>
        </ComboboxOption>

        <ComboboxOption
          v-for="result in results.results"
          :key="result.id"
          v-slot="{ active }"
          as="template"
          :value="encodeItem({ type: 'song', input: result.title, id: result.id })"
        >
          <li
            class="px-2 py-1 w-full border-b border-sky-200 last:border-b-0 transition-colors duration-75 cursor-pointer"
            :class="{ 'bg-sky-200 bg-opacity-10': active }"
          >
            <div class="truncate">
              <span class="i-carbon:music inline-block mr-px text-sky-600 text-sm transform translate-y-0.5" />
              <span
                class="transition-colors duration-75"
                :class="{ 'text-sky-500': active }"
              >
                {{ result.title }}
              </span>
            </div>
            <div class="truncate text-gray-600 text-sm">
              {{ result.album }}
              <span class="text-gray-400"> - </span>
              {{ result.artists.join(', ') }}
            </div>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </Transition>
  </Combobox>
</template>

<script lang="ts" setup>
import type { $Fetch } from 'ohmyfetch'

const router = useRouter()
const user = useUserStore()

const input = ref('')

const inputDebounced = ref('')
const updateInput = useDebounceFn(() => {
  inputDebounced.value = input.value
}, 400)
watch(input, (n, o) => {
  if (n === o)
    return

  // 在输入清空时立即更新
  if (n === '')
    inputDebounced.value = ''
  else updateInput()
})

// 搜索结果
const results = computedAsync(async (onCancel) => {
  if (!inputDebounced.value) {
    return {
      total: 0,
      max_score: 0,
      results: [],
    }
  }

  const abortController = new AbortController()
  onCancel(() => abortController.abort())

  try {
    // @ts-expect-error I have no idea why
    const data = await (user.fetchBEAuthed as $Fetch)<ApiSongSearch>(
      `/song/search?keyword=${inputDebounced.value}`,
      { signal: abortController.signal },
    )

    return data
  }
  catch (e) {
    return {
      total: 0,
      max_score: 0,
      results: [],
    }
  }
}, {
  total: 0,
  max_score: 0,
  results: [],
}, { lazy: true })

// 选项
type Item = {
  type: 'search'
  input: string
} | {
  type: 'song'
  input: string
  id: number
} | undefined
const encodeItem = (item: Item) => {
  if (item === undefined)
    return undefined
  return JSON.stringify(item)
}
const decodeItem = (item: string | null | undefined): Item => {
  if (!item)
    return undefined
  return JSON.parse(item)
}

// 选中结果
const selectedItemRaw = ref<string>()
const selectedItem = computed(() => decodeItem(selectedItemRaw.value))

// 将选中结果填入搜索框
const displayValue = (i: unknown) => {
  const item = decodeItem(i as string | undefined)

  if (item === undefined)
    return ''

  if (item.type === 'search')
    return item.input

  return ''
}

// 选中搜索结果
const { pause, resume } = watchPausable(selectedItem, (item) => {
  // 清空一下 input
  pause()
  inputDebounced.value = ''
  selectedItemRaw.value = undefined
  resume()

  if (item === undefined)
    return

  switch (item.type) {
    case 'search': {
      break
    }
    case 'song': {
      router.push(`/song/${item.id}`)
      break
    }
  }
})
</script>
