import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import App from './App.vue'
import generatedRoutes from '~pages'

import '@unocss/reset/tailwind.css'
import 'uno.css'

// app
const app = createApp(App)

// router
declare module 'vue-router' {
  interface RouteMeta {
  }
}
const routes = setupLayouts(generatedRoutes)
const router = createRouter({
  history: createWebHistory(),
  strict: true,
  routes,
})
app.use(router)

// pinia
const pinia = createPinia()
app.use(pinia)

app.mount('#app')
