<template>
  <div>
    <div class="h-16" />

    <div class="z-50 fixed flex bottom-0 py-2 w-full justify-center border-t border-sky-200 bg-white">
      <div class="flex w-full gap-3 max-w-screen-lg">
        <!-- 左侧控制 -->
        <div class="flex gap-2 items-center">
          <div class="p-px rounded-full ring ring-sky-300">
            <div class="i-carbon:chevron-left text-lg text-sky-600" />
          </div>
          <div class="relative p-1 rounded-full ring ring-sky-300">
            <div v-if="data.intendPlaying" class="i-carbon:pause text-2xl text-sky-600" @click="pause" />
            <div v-else class="i-carbon:play text-2xl text-sky-600" @click="play" />
            <div v-if="data.bufferFetching > 0" class="absolute i-carbon:smoothing -top-0.75 -right-0.75 text-sky-400 text-sm animate-spin" />
          </div>
          <div class="p-px rounded-full ring ring-sky-300">
            <div class="i-carbon:chevron-right text-lg text-sky-600" />
          </div>
        </div>

        <!-- 封面 -->
        <img
          v-if="data.song?.info.cover"
          :src="data.song?.info.cover"
          class="w-12 h-12 rounded border border-sky-200 "
        >
        <div v-else class="w-12 h-12 rounded border border-sky-200 bg-gray-200" />

        <div class="flex-auto flex flex-col gap-2 justify-center">
          <div class="flex justify-between">
            <!-- 标题 -->
            <div class="">
              <span>{{ data.song?.info.title || '少女祈祷中' }}</span>
              <span class="text-gray-500 text-sm"> - </span>
              <span class="text-gray-600 text-sm">{{ data.song?.info.album || '少女祈祷中' }}</span>
            </div>

            <!-- 播放详情 -->
            <div class="flex gap-1 text-gray-600 text-sm items-end">
              <div class="flex gap-px">
                <span>{{ formatTime(data.displayTimestamp) }}</span>
                <span class="text-gray-500">/</span>
                <span>{{ formatTime(data.song?.duration) }}</span>
              </div>
            </div>
          </div>

          <!-- 播放进度 -->
          <div
            ref="progressEl"
            class="relative w-full h-1 rounded-full ring ring-sky-300 bg-white"
            @click="progressBarClick"
          >
            <!-- 缓冲条 -->
            <template v-if="data.song">
              <div
                v-for="part of data.downloaded"
                :key="part.start"
                class="absolute top-0 h-full rounded-full bg-sky-200"
                :style="{
                  left: `${clamp(part.start / data.song.duration) * 100}%`,
                  width: `${(clamp(part.end / data.song.duration) - clamp(part.start / data.song.duration)) * 100}%`,
                }"
              />
            </template>
            <!-- 进度条 -->
            <div
              class="absolute top-0 h-full rounded-full bg-sky-300"
              :style="{
                width: `${progress * 100}%`,
              }"
            />
            <!-- 控制点 -->
            <div
              ref="progressKnobEl"
              class="absolute -top-1.5 w-4 h-4 rounded-full border border-sky-400 bg-white"
              :style="{
                left: `calc(${progress * 100}% - 0.375rem)`,
              }"
              @mousedown="progressKnobMouseDown"
            />
            <!-- 时间提示 -->
            <div
              v-if="isProgressKnobPressing && data.song"
              class="progress-knob-prompt absolute -top-9 px-1 rounded-md text-gray-700 text-sm border-1 border-sky-300 bg-white transform -translate-x-1/2"
              :style="{
                left: `calc(${progress * 100}% + 1px)`,
              }"
            >
              {{ formatTime(progress * data.song.duration) }}
            </div>
          </div>
        </div>

        <!-- 右侧控制 -->
        <div class="flex gap-2 items-center">
          <!-- 循环 -->
          <div class="i-carbon:retry-failed mx-0.5 text-sky-600 text-lg" />
          <!-- 音量 -->
          <div
            ref="volumeEl"
            class="relative"
            @mouseenter="volumeMouseEnter"
            @mouseleave="volumeMouseLeave"
          >
            <!-- 音量按钮 -->
            <div
              v-if="data.volume > 0"
              class="i-carbon:volume-up mx-0.5 text-sky-600 text-lg"
              @click="() => data.volume = 0"
            />
            <div
              v-else
              class="i-carbon:volume-mute mx-0.5 text-sky-600 text-lg"
              @click="() => data.volume = 0.5"
            />
            <!-- 音量框 -->
            <div
              v-show="isVolumeHovering || isVolumeKnobPressing"
              class="absolute -top-27 -left-0.5 -right-0.5 rounded-full ring ring-sky-300"
            >
              <div class="flex mb-1 pt-4 pb-2 justify-center rounded-full bg-white">
                <div
                  ref="volumeBarEl"
                  class="relative w-1 h-20 rounded-full ring ring-sky-300"
                >
                  <!-- 音量条 -->
                  <div
                    class="absolute w-full rounded-full bg-sky-400"
                    :style="{
                      top: `${(1 - data.volume) * 100}%`,
                      height: `${data.volume * 100}%`,
                    }"
                  />
                  <!-- 控制点 -->
                  <div
                    class="absolute -left-1.5 w-4 h-4 rounded-full border border-sky-400 bg-white"
                    :style="{
                      top: `calc(${(1 - data.volume) * 100}% - 0.5rem)`,
                    }"
                    @mousedown="volumeKnobMouseDown"
                  />
                  <!-- 百分比提示 -->
                  <div
                    v-if="isVolumeKnobPressing"
                    class="volume-knob-prompt absolute left-4.5 px-1 rounded-md text-gray-700 text-sm border-1 border-sky-300 bg-white transform -translate-y-1/2"
                    :style="{
                      top: `${(1 - data.volume) * 100}%`,
                    }"
                  >
                    {{ (data.volume * 100).toFixed(1) }}%
                  </div>
                </div>
              </div>
              <div
                class="h-7 opacity-0"
                @click="() => {
                  if (data.volume > 0) data.volume = 0
                  else data.volume = 0.5
                }"
              />
            </div>
          </div>
          <!-- 播放列表 -->
          <div class="i-carbon:list text-sky-600 text-xl" />
        </div>
      </div>
    </div>

    <audio ref="audioEl" />
  </div>
</template>

<script lang="ts" setup>
const data = usePlayerDashStore()

const audioEl = $shallowRef<HTMLAudioElement | null>(null)
const progressEl = $shallowRef<HTMLDivElement | null>(null)
const progressKnobEl = $shallowRef<HTMLDivElement | null>(null)
const volumeEl = $shallowRef<HTMLDivElement | null>(null)
const volumeBarEl = $shallowRef<HTMLDivElement | null>(null)

onMounted(() => {
  data.audioElement = audioEl
})
onUnmounted(() => {
  data.audioElement = null
})

const clamp = (num: number) => Math.max(0, Math.min(num, 1))

const formatTime = (time = 0) => {
  const minute = Math.floor(time / 60)
  const second = Math.floor(time % 60)
  return `${minute}:${second.toString().padStart(2, '0')}`
}

const play = () => {
  data.play()
}
const pause = () => {
  data.pause()
}

const seekByClientX = (clientX: number) => {
  if (!data.song || !progressEl)
    return
  const { left, width } = progressEl.getBoundingClientRect()
  const progress = clamp((clientX - left) / width)
  data.seek(progress * data.song.duration)
}
const progressBarClick = ({ clientX }: MouseEvent) => {
  seekByClientX(clientX)
}
let isProgressKnobPressing = $ref(false)
let progressTemp = $ref<number | undefined>()
const progress = $computed(() => progressTemp ?? data.progress)
const progressKnobMouseDown = () => {
  isProgressKnobPressing = true
  const stopMouseMoveListener = useEventListener('mousemove', ({ clientX }) => {
    if (!data.song || !progressEl)
      return
    const { left, width } = progressEl.getBoundingClientRect()
    progressTemp = clamp((clientX - left) / width)
  })
  const stopMouseUpListener = useEventListener('mouseup', ({ clientX }) => {
    progressTemp = undefined
    seekByClientX(clientX)

    isProgressKnobPressing = false
    stopMouseMoveListener()
    stopMouseUpListener()
  })
}

let isVolumeHovering = $ref(false)
const volumeMouseEnter = () => {
  isVolumeHovering = true
}
const volumeMouseLeave = () => {
  isVolumeHovering = false
}

let isVolumeKnobPressing = $ref(false)
const setVolumeByClientY = (clientY: number) => {
  if (!volumeBarEl)
    return
  const { top, height } = volumeBarEl.getBoundingClientRect()
  const precent = clamp(1 - ((clientY - top) / height))
  data.volume = precent
}
const volumeKnobMouseDown = ({ clientY }: MouseEvent) => {
  isVolumeKnobPressing = true
  setVolumeByClientY(clientY)
  const stopMouseMoveListener = useEventListener('mousemove', ({ clientY }) => {
    setVolumeByClientY(clientY)
  })
  const stopMouseUpListener = useEventListener('mouseup', ({ clientY }) => {
    setVolumeByClientY(clientY)

    isVolumeKnobPressing = false
    stopMouseMoveListener()
    stopMouseUpListener()
  })
}
</script>

<style scoped>
.volume-knob-prompt::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
  background: white;
  border-left: 1px solid theme('colors.sky.300');
  border-bottom: 1px solid theme('colors.sky.300');
  position: absolute;
  top: calc(50% - 0.25rem);
  left: -0.3rem;
}

.progress-knob-prompt::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
  background: white;
  border-bottom: 1px solid theme('colors.sky.300');
  border-right: 1px solid theme('colors.sky.300');
  position: absolute;
  left: calc(50% - 0.25rem);
  bottom: -0.3rem;
}
</style>
