import { StorageSerializers } from '@vueuse/core'
import jwt_decode from 'jwt-decode'
import { $fetch } from 'ohmyfetch'
import defaultAvatar from '@/assets/akarin.jpg'
import type { ApiPublicLogin, ApiUserById } from '@/composables/fetch'

export const useUserStore = defineStore('user', () => {
  const token = useLocalStorage<string>('thrill-user-token', '', {
    writeDefaults: false,
    serializer: StorageSerializers.string,
    listenToStorageChanges: true,
  })
  const status = useLocalStorage<number | undefined>('thrill-user-status', undefined, {
    writeDefaults: false,
    serializer: StorageSerializers.number,
    listenToStorageChanges: true,
  })

  const jwtPayload = computed(() => {
    try {
      return jwt_decode<{
        id: number
        email: string
        exp: number
        iat: number
      }>(token.value)
    }
    catch (e) {
      return null
    }
  })
  const id = computed(() => jwtPayload.value?.id)
  const email = computed(() => jwtPayload.value?.email)
  const isLogin = computed(() => jwtPayload.value?.id !== undefined)

  const fetchBEAuthed = computed(() => $fetch.create({
    baseURL: import.meta.env.VITE_BACKEND_BASE,
    headers: { Authorization: `Bearer ${token.value}` },
  }))
  const fetchAuthed = computed(() => $fetch.create({
    headers: { Authorization: `Bearer ${token.value}` },
  }))

  // 通过 api 拉取数据
  const info = ref<ApiUserById | undefined>()
  const infoActive = ref(false)
  watch([infoActive, id], async () => {
    try {
      if (!infoActive.value || id.value === undefined) {
        info.value = undefined
        return
      }

      const data = await fetchBEAuthed.value<ApiUserById>(`/user/${id.value}`)

      if (!data.email) {
        info.value = undefined
        return
      }

      info.value = data
    }
    catch (e) {}
  }, { flush: 'pre', deep: true })
  const username = computed(() => {
    infoActive.value = true
    return info.value?.username ?? 'Loading...'
  })
  const bio = computed(() => {
    infoActive.value = true
    return info.value?.bio ?? 'Loading...'
  })
  const avatar = computed(() => {
    infoActive.value = true
    return info.value?.avatar?.url ?? defaultAvatar
  })

  // 登录用户
  async function login(email: string, password: string) {
    const data = await fetchBE<ApiPublicLogin>(
      '/public/login',
      {
        method: 'POST',
        body: {
          email,
          password,
        },
      },
    )

    token.value = data.token
    status.value = data.status
  }

  // 清除状态（登出）
  function clear() {
    token.value = ''
    status.value = undefined
  }

  // 状态校验
  watch(jwtPayload, (n) => {
    if (!token.value)
      return

    // 检查 token 是否正确
    if (!n) {
      clear()
      return
    }

    // 检查 token 是否在有效期内
    if (n.exp * 1000 < Date.now())
      clear()
  }, { immediate: true })

  return {
    token,
    status,

    id,
    email,
    isLogin,

    fetchAuthed,
    fetchBEAuthed,

    info,
    username,
    bio,
    avatar,

    login,
    clear,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
