import { StorageSerializers } from '@vueuse/core'
import type { $Fetch } from 'ohmyfetch'
import type { ApiSongInfo } from '@/composables/fetch'

interface Range {
  start: number
  end: number
}

interface RepresentationSegment {
  index: number
  // the time range of this segment in seconds
  timeRange: Range
  // the data range of this segment
  mediaRange: Range
  // the data range of this segment's index
  indexRange: Range
  // fetched data
  data?: ArrayBuffer
}

interface Representation {
  // audio stream id, eg. 0
  id: string
  // audio mime, eg. audio/mp4; codecs="flac"
  mime: string
  // is mime supported by browser
  isSupported: boolean
  // bitrate in bits per second
  bandwidth: number
  // sample rate in Hz
  audioSamplingRate: number

  // audio url, eg. https://static.thrill.moe/35cd40272d1cf4e1c6afa31ce049da17-stream0.mp4
  url: string
  // duration of each segment in seconds
  segmentDuration: number
  // initial segment
  initialSegment: {
    data?: ArrayBuffer
    range: Range
  }
  // stream segments
  segments: RepresentationSegment[]
}

interface SongData {
  // song id
  id: number
  // info
  info: ApiSongInfo
  // duration in seconds
  duration: number
  // url of the dash manifest
  dashUrl: string
  // representations of audio stream
  representations: Representation[]
}

export const usePlayerDashStore = defineStore('player-dash', () => {
  const user = useUserStore()

  // volume from 0 to 1
  const volume = useLocalStorage<number>('thrill-volume', 1, {
    writeDefaults: false,
    serializer: StorageSerializers.number,
    listenToStorageChanges: true,
  })
  // current song id
  const currentId = useSessionStorage<number | undefined>('thrill-current-id', undefined, {
    writeDefaults: false,
    serializer: StorageSerializers.number,
    listenToStorageChanges: true,
  })

  // user bandwidth in bytes per second
  const userBandwidth = useSessionStorage('thrill-user-bandwidth', 0, {
    writeDefaults: false,
    serializer: StorageSerializers.number,
    listenToStorageChanges: true,
  })
  function recordBandwidth(start: number, end: number, size: number) {
    const downloadSpeed = size / (end - start)
    if (Number.isFinite(downloadSpeed))
      userBandwidth.value = userBandwidth.value * 0.2 + (downloadSpeed * 800)
  }
  watch(userBandwidth, (value) => {
    if (!Number.isFinite(value))
      userBandwidth.value = 0
  }, { flush: 'pre', immediate: true })

  const audioElement = shallowRef<HTMLAudioElement | null>(null)
  const mediaSource = shallowRef<MediaSource | null>(null)
  const sourceBuffer = shallowRef<SourceBuffer | null>(null)
  watch([audioElement, mediaSource], ([newEl, newSource], [oldEl, oldSource]) => {
    if (newSource !== oldSource) {
      if (oldEl?.src) {
        URL.revokeObjectURL(oldEl.src)
        oldEl.src = ''
      }
      if (newSource && newEl)
        newEl.src = URL.createObjectURL(newSource)

      sourceBuffer.value = null
    }

    if (newEl === null && oldEl !== null && oldEl.src)
      URL.revokeObjectURL(oldEl.src)
    if (newEl !== null && oldEl === null && newSource)
      newEl.src = URL.createObjectURL(newSource)
  }, { flush: 'pre' })
  watch([audioElement, volume], ([audioElement, volume]) => {
    if (audioElement)
      audioElement.volume = volume
  })

  function waitSourceBufferUpdateEnd() {
    return new Promise<void>((resolve) => {
      const stop = useEventListener(sourceBuffer.value, 'updateend', () => {
        stop()
        resolve()
      })
    })
  }

  const song = ref<SongData>()

  const intendPlaying = ref(false)
  const playing = ref(false)
  watch(playing, (playing) => {
    if (playing)
      audioElement.value?.play()
    else
      audioElement.value?.pause()
  })

  // playback timestamp in seconds, refreshs every frame when playing
  const displayTimestamp = ref(0)
  const {
    isActive: isTimestampRefreshing,
    pause: pauseTimestampRefresh,
    resume: resumeTimestampRefresh,
  } = useRafFn(() => {
    if (audioElement.value === null)
      return

    displayTimestamp.value = audioElement.value.currentTime
  }, { immediate: false })
  watch([audioElement, playing], ([audioEl, playing]) => {
    const shouldRefresh = audioEl !== null && playing
    if (isTimestampRefreshing.value !== shouldRefresh) {
      if (shouldRefresh)
        resumeTimestampRefresh()
      else
        pauseTimestampRefresh()
    }
  }, { immediate: true })

  // playback progress
  const progress = computed(() => {
    if (song.value === undefined)
      return 0
    return displayTimestamp.value / song.value.duration
  })

  async function fetchInitialSegment(rep: Representation) {
    if (rep.initialSegment.data)
      return rep.initialSegment.data
    const res = await fetch(rep.url, {
      headers: {
        Range: `bytes=${rep.initialSegment.range.start}-${rep.initialSegment.range.end}`,
      },
    })
    const data = await res.arrayBuffer()
    rep.initialSegment.data = data
    return data
  }

  const activeRepresentation = ref<Representation>()
  const fetching = ref(0)

  async function seekFetch() {
    if (!sourceBuffer.value)
      return

    // check if targetTimestamp is in buffered range
    const buffered = sourceBuffer.value.buffered
    for (let i = 0; i < buffered.length; i++) {
      const start = buffered.start(i)
      const end = buffered.end(i)
      // if it's in range, we can stop
      if (start <= displayTimestamp.value && displayTimestamp.value <= end)
        return
    }

    const rep = activeRepresentation.value
    if (rep === undefined)
      return

    const segment = rep.segments[Math.floor(displayTimestamp.value / rep.segmentDuration)]
    if (segment === undefined)
      return

    // if segment is already fetched, just append it
    if (segment.data !== undefined) {
      try {
        sourceBuffer.value.appendBuffer(segment.data)
        await waitSourceBufferUpdateEnd()
      }
      catch (e) {
        try {
          sourceBuffer.value.remove(0, Infinity)
          await waitSourceBufferUpdateEnd()
          sourceBuffer.value.appendBuffer(segment.data)
          await waitSourceBufferUpdateEnd()
        }
        catch (e) {}
      }
      return
    }

    // if not, we need to fetch the target segment
    fetching.value++
    const res = await fetch(rep.url, {
      headers: {
        Range: `bytes=${segment.mediaRange.start}-${segment.mediaRange.end}`,
      },
    })
    let downloadStart = performance.now()
    const reader = res.body!.getReader()
    const data = new Uint8Array(segment.mediaRange.end - segment.mediaRange.start + 1)
    let cursor = 0
    for (;;) {
      const { done, value } = await reader.read()
      if (done)
        break

      const downloadEnd = performance.now()
      recordBandwidth(downloadStart, downloadEnd, value.length)
      downloadStart = downloadEnd

      data.set(new Uint8Array(value), cursor)
      cursor += value.length
    }

    segment.data = data
    try {
      sourceBuffer.value.appendBuffer(data)
    }
    catch (e) {
      sourceBuffer.value.remove(0, Infinity)
      await waitSourceBufferUpdateEnd()
      sourceBuffer.value.appendBuffer(data)
    }
    await waitSourceBufferUpdateEnd()

    refreshDownloaded()

    fetching.value--
  }
  async function loadRepresentation(rep: Representation) {
    if (!rep.isSupported)
      return 'not-supported-mime'
    if (!sourceBuffer.value) {
      if (!mediaSource.value)
        return 'no-media-source'

      sourceBuffer.value = mediaSource.value.addSourceBuffer(rep.mime)
    }

    if (activeRepresentation.value !== rep) {
      activeRepresentation.value = rep
      fetching.value++
      const data = await fetchInitialSegment(rep)
      sourceBuffer.value.appendBuffer(data)
      await waitSourceBufferUpdateEnd()
      fetching.value--
    }
  }

  const downloaded = ref<{
    start: number
    end: number
  }[]>([])
  function refreshDownloaded() {
    downloaded.value = activeRepresentation.value?.segments
      .reduce((acc, seg, index, segs) => {
        if (seg.data) {
          if (index !== 0 && segs[index - 1].data) {
            acc[acc.length - 1].end = seg.timeRange.end
          }
          else {
            acc.push({
              start: seg.timeRange.start,
              end: seg.timeRange.end,
            })
          }
        }

        return acc
      }, [] as { start: number; end: number }[]) || []
  }

  async function keepBuffer() {
    if (!sourceBuffer.value)
      return
    if (fetching.value)
      return

    // calculate how long will the current buffer last
    let bufferEnd = 0
    const buffered = sourceBuffer.value.buffered
    for (let i = 0; i < buffered.length; i++) {
      const start = buffered.start(i)
      const end = buffered.end(i)
      if (start < displayTimestamp.value && displayTimestamp.value < end) {
        bufferEnd = end
        break
      }
    }
    let bufferLeft = bufferEnd - displayTimestamp.value

    // target buffer length: 30s
    if (bufferLeft > 30)
      return

    const rep = activeRepresentation.value
    if (rep === undefined)
      return

    const index = Math.floor(bufferEnd / rep.segmentDuration)
    const segments = rep.segments.slice(index)
    if (segments.length === 0)
      return

    // append segments that have data
    if (segments[0].data) {
      for (;;) {
        if (!segments[0]?.data)
          break

        const segment = segments.shift()!

        try {
          sourceBuffer.value.appendBuffer(segment.data!)
          bufferLeft += rep.segmentDuration
        }
        catch (e) {
          return
        }
        await waitSourceBufferUpdateEnd()
      }
      if (bufferLeft > 30 || segments.length === 0)
        return
    }

    // calculate how many segments should be fetched at once
    let targetSize = bufferLeft * userBandwidth.value * 0.8
    let currentSize = 0
    const segmentsToFetch = []
    for (const segment of segments) {
      if (segment.data !== undefined)
        break
      const segmentSize = segment.mediaRange.end - segment.mediaRange.start
      if (currentSize + segmentSize > targetSize)
        break
      currentSize += segmentSize
      targetSize += rep.segmentDuration * userBandwidth.value * 0.6
      segmentsToFetch.push(segment)
    }
    if (segmentsToFetch.length === 0)
      segmentsToFetch.push(segments[0])

    // join segment range
    const start = segmentsToFetch[0].mediaRange.start
    const end = segmentsToFetch[segmentsToFetch.length - 1].mediaRange.end
    const segmentTemps = segmentsToFetch.map((seg) => {
      const size = seg.mediaRange.end - seg.mediaRange.start + 1
      return {
        index: seg.index,
        size,
        cursor: 0,
        left: size,
        data: new Uint8Array(size),
      }
    })

    // fetch the segments
    fetching.value++
    const res = await fetch(rep.url, {
      headers: {
        Range: `bytes=${start}-${end}`,
      },
    })
    let downloadStart = performance.now()
    const reader = res.body!.getReader()
    let canPushBuffer = true
    for (;;) {
      const data = await reader.read()
      if (data.done)
        break

      let { value } = data

      const downloadEnd = performance.now()
      recordBandwidth(downloadStart, downloadEnd, value.length)
      downloadStart = downloadEnd

      while (value.length) {
        if (value.length < segmentTemps[0].left) {
          segmentTemps[0].data.set(value, segmentTemps[0].cursor)
          segmentTemps[0].cursor += value.length
          segmentTemps[0].left -= value.length
          break
        }
        else {
          const temp = segmentTemps.shift()!
          temp.data.set(value.slice(0, temp.left), temp.cursor)
          rep.segments[temp.index].data = temp.data
          refreshDownloaded()
          if (canPushBuffer) {
            try {
              sourceBuffer.value.appendBuffer(temp.data)
              await waitSourceBufferUpdateEnd()
            }
            catch (e) {
              canPushBuffer = false
            }
          }
          value = value.slice(temp.left)
        }
      }
    }
    fetching.value--
  }

  const {
    pause: pauseKeepBuffer,
    resume: resumeKeepBuffer,
  } = useIntervalFn(() => {
    keepBuffer()
  }, 100, { immediate: false })
  watch(playing, (playing) => {
    if (playing)
      resumeKeepBuffer()
    else
      pauseKeepBuffer()
  }, { immediate: true })

  // load song info to song
  async function load(info: ApiSongInfo) {
    const mpd = info.streams.find(stream => stream.codec === 'mpd')
    if (!mpd)
      return 'no-mpd'

    // fetch mpd
    const manifest = await (user.fetchAuthed as unknown as $Fetch)<string>(mpd.playurl, { parseResponse: txt => txt })
    // console.log(manifest)

    const domParser = new DOMParser()
    const doc = domParser.parseFromString(manifest, 'application/xml').documentElement
    if (doc.nodeName !== 'MPD')
      return 'invalid-manifest'

    // duration in milliseconds
    const duration = parseFloat(info.duration)

    const period = ([].slice.call(doc.childNodes) as Element[])
      .find(node => node.nodeName === 'Period')
    if (!period)
      return 'no-period'

    const audioAdaptationSet = ([].slice.call(period.childNodes) as Element[])
      .find(node => node.nodeName === 'AdaptationSet')
    if (!audioAdaptationSet)
      return 'no-audio-adaptation-set'

    const representations = ([].slice.call(audioAdaptationSet.childNodes) as Element[])
      .filter(node => node.nodeName === 'Representation')
      .map((rep): Representation => {
        const id = rep.getAttribute('id')!
        const mime = `${rep.getAttribute('mimeType')!}; codecs="${rep.getAttribute('codecs')!}"`
        const isSupported = MediaSource.isTypeSupported(mime)
        const bandwidth = parseInt(rep.getAttribute('bandwidth')!)
        const audioSamplingRate = parseInt(rep.getAttribute('audioSamplingRate')!)

        const children = ([].slice.call(rep.childNodes) as Element[])
        const baseUrl = children.find(node => node.nodeName === 'BaseURL')!.innerHTML
        const url = new URL(baseUrl, mpd.playurl).toString()

        const segmentList = children.find(node => node.nodeName === 'SegmentList')!
        const segmentDuration = parseInt(segmentList.getAttribute('duration')!)
          / parseInt(segmentList.getAttribute('timescale')!)
        const segmentNodes = ([].slice.call(segmentList.childNodes) as Element[])

        const initialSegmentEl = segmentNodes.find(node => node.nodeName === 'Initialization')
        const initialSegmentRange = initialSegmentEl!.getAttribute('range')!.split('-').map(str => parseInt(str))
        const initialSegment = {
          range: {
            start: initialSegmentRange[0],
            end: initialSegmentRange[1],
          },
        }

        const segments = segmentNodes
          .filter(node => node.nodeName === 'SegmentURL')
          .map((node, index): RepresentationSegment => {
            const mediaRange = node.getAttribute('mediaRange')!.split('-').map(str => parseInt(str))
            const indexRange = node.getAttribute('indexRange')!.split('-').map(str => parseInt(str))
            return {
              index,
              timeRange: {
                start: index * segmentDuration,
                end: (index + 1) * segmentDuration,
              },
              mediaRange: {
                start: mediaRange[0],
                end: mediaRange[1],
              },
              indexRange: {
                start: indexRange[0],
                end: indexRange[1],
              },
            }
          })

        return {
          id,
          mime,
          isSupported,
          bandwidth,
          audioSamplingRate,

          url,
          segmentDuration,
          initialSegment,
          segments,
        }
      })

    song.value = {
      id: info.id,
      info,
      duration,
      dashUrl: mpd.playurl,
      representations,
    }

    const source = new MediaSource()
    mediaSource.value = source

    await new Promise<void>((resolve) => {
      const stop = useEventListener(source, 'sourceopen', () => {
        stop()
        resolve()
      })
    })

    source.duration = duration

    // TODO adaptive streaming
    await loadRepresentation(representations[0])
  }

  async function set(info: ApiSongInfo) {
    currentId.value = info.id
    return await load(info)
  }

  async function play() {
    if (!song.value)
      return 'no-song'
    if (!audioElement.value)
      return 'no-audio-element'

    intendPlaying.value = true

    await seekFetch()

    playing.value = true
  }

  async function pause() {
    if (!song.value)
      return 'no-song'
    if (!audioElement.value)
      return 'no-audio-element'

    intendPlaying.value = false

    playing.value = false
  }

  async function seek(time: number) {
    if (!song.value)
      return 'no-song'
    if (!audioElement.value)
      return 'no-audio-element'

    playing.value = false

    displayTimestamp.value = time
    audioElement.value.currentTime = time
    await seekFetch()

    if (intendPlaying.value)
      playing.value = true
  }

  until(audioElement).toBeTruthy().then(async () => {
    if (currentId.value) {
      fetching.value++
      const data = await (user.fetchBEAuthed as unknown as $Fetch)<ApiSongInfo>(`/song/info?id=${currentId.value}`)
      await load(data)
      fetching.value--
    }
  })

  return {
    audioElement,
    mediaSource,

    song,

    intendPlaying,
    playing,
    volume,
    displayTimestamp,
    progress,
    bufferFetching: fetching,
    downloaded,

    load,
    set,
    play,
    pause,
    seek,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePlayerDashStore, import.meta.hot))
